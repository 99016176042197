<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Determine the shape and bond angles that best describe the following oxyanions of nitrogen:
      </p>

      <div
        v-for="(part, i) in parts"
        :key="part.letter"
        :class="i !== parts.length - 1 ? 'mb-3' : 'mb-0'"
      >
        <stemble-latex class="mb-0" :content="`$\\text{${part.letter}) }${part.text}$`" />

        <v-radio-group v-model="inputs[`input${i + 1}`]" class="pl-8">
          <v-radio v-for="option in geometryOptions" :key="option.value" :value="option.value">
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </v-radio-group>
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

const LINEAR = 'a';
const TRIGONAL_PLANAR = 'b';
const TETRAHEDRAL = 'c';
const BENT_120 = 'd';
const BENT_1095 = 'e';
const PYRAMIDAL = 'f';

export default {
  name: 'Question38',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
      parts: [
        {
          letter: 'a',
          text: '\\ce{NO3^-}\\text{ anion}',
        },
        {
          letter: 'b',
          text: '\\ce{NO2^+}\\text{ cation}',
        },
        {
          letter: 'c',
          text: '\\ce{NO2^-}\\text{ anion}',
        },
      ],
      geometryOptions: [
        {text: '$\\text{Linear - }180^o$', value: LINEAR},
        {text: '$\\text{Trigonal Planar - }120^o$', value: TRIGONAL_PLANAR},
        {
          text: '$\\text{Tetrahedral }\\sim\\text{ }109.5^o$',
          value: TETRAHEDRAL,
        },
        {text: '$\\text{Bent }\\sim\\text{ }120^o$', value: BENT_120},
        {text: '$\\text{Bent }\\sim\\text{ }109.5^o$', value: BENT_1095},
        {text: '$\\text{Pyramidal }\\sim\\text{ }109.5^o$', value: PYRAMIDAL},
      ],
    };
  },
};
</script>
